<template>
  <Popover as="header" class="relative">
    <div class="bg-xd_red">
      <nav
        class="relative mx-auto flex max-w-7xl items-center justify-between px-6 pt-12 xl:px-8"
        aria-label="Global"
      >
        <!-- <p><strong>Current route path:</strong> {{ $route.fullPath }}</p> -->
        <div class="flex flex-1 items-center justify-between">
          <div class="flex w-full items-center justify-between lg:w-auto">
            <div>
              <!-- <AppLogo></AppLogo> -->
              <!-- <span class="sr-only">XAHOD</span> -->
              <div class="h-8 w-auto text-xd_red sm:h-10" style="height: 150px">
                <AppLogo></AppLogo>
                <!-- <h3
                  class="text-7xl font-extrabold uppercase leading-6 tracking-wider"
                >
                  XAHOD
                </h3>
                <p class="mt-6 text-2xl tracking-widest">Ltd.</p> -->
              </div>
            </div>
            <div class="-mr-2 flex items-center lg:hidden">
              <PopoverButton
                class="inline-flex items-center justify-center rounded-md bg-xd_red p-2 text-xd_black focus-ring-inset hover:bg-black hover:text-xd_white focus:outline-none focus:ring-2 focus:ring-xd_red"
              >
                <span class="sr-only">Open main menu</span>
                <Bars3Icon class="h-8 w-8" aria-hidden="true" />
              </PopoverButton>
            </div>
          </div>
          <div class="hidden space-x-10 lg:ml-10 lg:flex">
            <RouterLink
              class="text-base text-2xl font-medium text-xd_black font-primary header-item"
              to="/faq"
              activeClass="active-page"
              :key="$router.fullPath"
              exactActiveClass="active-page"
              ><div class="middle">
                <span class="container"
                  ><div class="text" data-text="FAQ">FAQ</div></span
                >
              </div></RouterLink
            >
            <RouterLink
              class="text-base text-2xl font-medium text-xd_black font-primary header-item"
              to="/about"
              :key="$router.fullPath"
              activeClass="active-page"
              exactActiveClass="active-page"
              ><div class="middle">
                <span class="container"
                  ><div class="text" data-text="ABOUT">ABOUT</div></span
                >
              </div></RouterLink
            >

            <RouterLink
              class="text-base text-2xl font-medium text-xd_black font-primary header-item"
              to="/download"
              activeClass="active-download"
              exactActiveClass="active-download"
            >
              <div class="middle-dwnld">
                <span class="container-dwnld"
                  ><div class="text-dwnld" data-text="DOWNLOAD">
                    DOWNLOAD
                  </div></span
                >
              </div>
            </RouterLink>

            <!-- <RouterLink
              class="text-base text-2xl font-medium text-xd_black font-primary header-item"
              :key="$router.fullPath"
              to="/about"
              activeClass="active-page"
              exactActiveClass="active-page"
              >About</RouterLink
            > -->
            <!-- <a
              class="text-base text-2xl font-medium text-xd_black font-primary header-item"
              >Download</a
            > -->
          </div>
        </div>
      </nav>
    </div>

    <transition
      enter-active-class="duration-150 ease-out"
      enter-from-class="scale-95 opacity-0"
      enter-to-class="scale-100 opacity-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="scale-100 opacity-100"
      leave-to-class="scale-95 opacity-0"
    >
      <PopoverPanel
        focus
        class="absolute inset-x-0 top-0 z-30 origin-top transform p-2 transition lg:hidden"
      >
        <div
          class="overflow-hidden rounded-lg bg-xd_red shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="flex items-center justify-between px-5 pt-4">
            <div></div>
            <div class="-mr-2">
              <PopoverButton
                class="inline-flex items-center justify-center rounded-md bg-xd_red p-2 text-xd_black hover:bg-black hover:text-xd_white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-xd_red"
              >
                <span class="sr-only">Close menu</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </PopoverButton>
            </div>
          </div>
          <div class="pt-1 pb-4">
            <div class="px-2 space-y-1">
              <RouterLink
                class="block rounded-md px-3 py-2 text-base font-medium text-xd_black hover:xd_black font-primary"
                to="/faq"
                activeClass="active-page-mobile"
                >FAQ</RouterLink
              >
              <RouterLink
                class="block rounded-md px-3 py-2 text-base font-medium text-xd_black hover:xd_black font-primary"
                to="/about"
                activeClass="active-page-mobile"
                >ABOUT</RouterLink
              >
              <RouterLink
                class="block rounded-md px-3 py-2 text-base font-medium text-xd_black hover:xd_black font-primary"
                to="/download"
                activeClass="active-page-mobile"
                >DOWNLOAD</RouterLink
              >
              <!-- <a
                class="block rounded-md px-3 py-2 text-base font-medium text-xd_black hover:bg-xd_red font-primary"
                >DOWNLOAD</a
              > -->
            </div>
            <!-- <div class="mt-6 px-5">
              <a
                href="#"
                class="block w-full rounded-md border border-transparent bg-xd_black px-4 py-2 text-center font-medium text-xd_red shadow hover:bg-xd_black"
                >Download</a
              >
            </div> -->
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import AppLogo from './AppLogo.vue'

import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'headercomp',
  components: {
    AppLogo,
    Popover,
    PopoverButton,
    PopoverPanel,
    Bars3Icon,
    XMarkIcon,
  },
  props: {},
  setup(props) {
    const navigation = [
      { name: 'FAQ', href: '#' },
      // { name: 'Contact', href: '#' },
      { name: 'About', href: '#' },
      { name: 'Download', href: '#' },
    ]

    return {
      navigation,
    }
  },
})
</script>

<style lang="scss" scoped>
.middle {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}

.container {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
}

.text {
  font-size: 100%;
  text-transform: uppercase;
  position: relative;
  color: black;
  padding-top: 10px;
}

.text::before {
  content: attr(data-text);
  position: absolute;
  color: #fff;
  width: 0;
  overflow: hidden;
  transition: 0.6s;
}

.container::before {
  content: '';
  width: 0%;
  height: 150%;
  position: absolute;
  background: black;
  right: 0;
  top: 0;
  transition: 0.3s;
}

.container:hover .text::before,
.container:hover::before {
  width: 100%;
}

@keyframes colorchange {
  from {
    color: black;
  }
  to {
    color: white;
  }
}

.middle-dwnld {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}

.container-dwnld {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
}

.text-dwnld {
  font-size: 100%;
  text-transform: uppercase;
  position: relative;
  color: black;
  padding-top: 10px;
}

.text-dwnld::before {
  content: attr(data-text);
  position: absolute;
  color: #fff;
  width: 0;
  overflow: hidden;
  transition: 0.6s;
}

.container-dwnld::before {
  content: '';
  width: 0%;
  height: 150%;
  position: absolute;
  background: black;
  right: 0;
  top: 0;
  transition: 0.3s;
}

.container-dwnld:hover .text-dwnld::before,
.container-dwnld:hover::before {
  width: 100%;
}

@keyframes colorchange_dwnld {
  from {
    color: black;
  }
  to {
    color: white;
  }
}

// @keyframes boxchange {
//   from {
//     content: '';
//     width: 0%;
//     height: 150%;
//     position: absolute;
//     background: black;
//     right: 0;
//     top: 0;
//   }
//   to {
//     content: '';
//     width: 0%;
//     height: 0%;
//     position: absolute;
//     background: black;
//     right: 0;
//     top: 0;
//   }
// }

.active-page-mobile {
  color: white;
  background-color: black;
}

.active-page div span div {
  animation: colorchange 0ms;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.active-download div span div {
  animation: colorchange_dwnld 0ms;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}
// .active-page div span div::before {
//   animation: boxchange 0ms;
//   animation-delay: 0.3s;
//   animation-fill-mode: forwards;
// }
</style>
