<template>
  <footer class="bg-xd_black" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="space-y-10 xl:col-span-1">
          <div class="h-16 w-auto text-xd_red">
            <!-- <AppLogo :schemes="'white-on-black'"></AppLogo> -->
            <h3
              class="text-7xl font-extrabold uppercase leading-6 tracking-wider"
            >
              XAHOD
            </h3>
            <p class="mt-6 text-2xl tracking-widest">XAH on Demand</p>
          </div>
          <div class="flex space-x-6">
            <a
              v-for="item in footerNavigation.social"
              :key="item.name"
              :href="item.href"
              :target="item.target"
              class="text-xd_black hover:text-xd_black"
            >
              <span class="sr-only">{{ item.name }}</span>
              <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div class="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3
                class="text-base font-medium text-stone-200 font-primary"
                style="text-decoration: underline"
              >
                SUPPORT
              </h3>
              <ul role="list" class="mt-4 space-y-4">
                <li>
                  <RouterLink
                    class="text-base text-xd_white hover:text-xd_white font-secondary"
                    to="/faq"
                    >FAQ
                  </RouterLink>
                </li>
                <li>
                  <a
                    href="mailto:support@xahod.com"
                    class="text-base text-xd_white hover:text-xd_white font-secondary"
                    >Inquiries</a
                  >
                </li>
                <!-- <li v-for="item in footerNavigation.support" :key="item.name">
                  <a
                    :href="item.href"
                    class="text-base text-xd_white hover:text-xd_white font-secondary"
                    >{{ item.name }}</a
                  >
                </li> -->
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3
                class="text-base font-medium text-stone-200 font-primary"
                style="text-decoration: underline"
              >
                APP
              </h3>
              <ul role="list" class="mt-4 space-y-4">
                <li>
                  <RouterLink
                    class="text-base text-xd_white hover:text-xd_white font-secondary"
                    to="/about"
                    >About
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    class="text-base text-xd_white hover:text-xd_white font-secondary"
                    to="/download"
                    >Download
                  </RouterLink>
                </li>

                <!-- <li v-for="item in footerNavigation.company" :key="item.name">
                  <a
                    :href="item.href"
                    class="text-base text-xd_white hover:text-xd_white font-secondary"
                    >{{ item.name }}</a
                  >
                </li> -->
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3
                class="text-base font-medium text-stone-200 font-primary"
                style="text-decoration: underline"
              >
                LEGAL
              </h3>
              <RouterLink
                class="text-base text-xd_white hover:text-xd_white font-secondary"
                to="/tos"
                >Terms of Service
              </RouterLink>
              <!-- <ul role="list" class="mt-4 space-y-4">
                <li v-for="item in footerNavigation.legal" :key="item.name">
                  <RouterLink
                    class="text-base text-xd_white hover:text-xd_white font-secondary"
                    to="/tos"
                    >Terms of Service
                  </RouterLink>
                  <a
                    :href="item.href"
                    class="text-base text-xd_white hover:text-xd_white font-secondary"
                    >{{ item.name }}</a
                  >
                </li>
              </ul> -->
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 border-t border-xd_white pt-8">
        <p class="text-base text-xd_white xl:text-center">
          &copy; {{ new Date().getFullYear() }} XAHOD Ltd.
        </p>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent, h } from 'vue'
import AppLogo from './AppLogo.vue'

export default defineComponent({
  name: 'footercomp',
  components: { AppLogo },

  setup(props) {
    const footerNavigation = {
      support: [
        { name: 'FAQ', href: '#' },
        { name: 'Contact', href: '#' },
      ],
      company: [
        { name: 'About', href: '#' },
        { name: 'Download', href: '#' },
      ],
      legal: [{ name: 'Terms of Service', href: '#' }],
      social: [
        {
          name: 'X',
          href: 'https://twitter.com/XAHOnDemand',
          target: '_blank',
          icon: defineComponent({
            render: () =>
              h('svg', { fill: 'white', viewBox: '0 0 24 24' }, [
                h('path', {
                  d: 'M21.159809,2C21.623091,2,22,2.376909,22,2.840191v18.319618C22,21.623091,21.623091,22,21.159809,22H2.84019C2.37691,22,2,21.623091,2,21.159809V2.840191C2,2.376909,2.37691,2,2.84019,2H21.159809 M21.159809,1H2.84019C1.82388,1,1,1.823879,1,2.840191v18.319618C1,22.176121,1.82388,23,2.84019,23h18.319618C22.176121,23,23,22.176121,23,21.159809V2.840191C23,1.823879,22.176121,1,21.159809,1L21.159809,1z',
                }),
                h('path', {
                  d: 'M13.523985,10.775623L19.480984,4h-1.41143l-5.174801,5.88195L8.764665,4H4l6.246901,8.895341L4,20h1.411431l5.461361-6.21299L15.235336,20H20L13.523985,10.775623z M11.590199,12.973429l-0.633908-0.886331L5.920428,5.041171h2.168246l4.065295,5.6884l0.631236,0.886331l5.283681,7.39365H15.90064L11.590199,12.973429z',
                }),
              ]),
          }),
        },
      ],

      // <?xml version="1.0" ?><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" width="24px" height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><g><polygon points="12.153992,10.729553 8.088684,5.041199 5.92041,5.041199 10.956299,12.087097 11.59021,12.97345    15.900635,19.009583 18.068909,19.009583 12.785217,11.615906  "/><path d="M21.15979,1H2.84021C1.823853,1,1,1.823853,1,2.84021v18.31958C1,22.176147,1.823853,23,2.84021,23h18.31958   C22.176147,23,23,22.176147,23,21.15979V2.84021C23,1.823853,22.176147,1,21.15979,1z M15.235352,20l-4.362549-6.213013   L5.411438,20H4l6.246887-7.104675L4,4h4.764648l4.130127,5.881958L18.06958,4h1.411377l-5.95697,6.775635L20,20H15.235352z"/></g></svg>
    }
    return {
      footerNavigation,
    }
  },
})
</script>
