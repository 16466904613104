<template>
  <HeaderComp />
  <div class="bg-xd_red">
    <Home></Home>
    <!-- <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view> -->
    <FooterComp />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Home from './pages/Home.vue'
import { HeaderComp, FooterComp } from '@/components'

export default defineComponent({
  name: 'app',
  components: { Home, HeaderComp, FooterComp },

  setup() {
    return {}
  },
})
</script>
