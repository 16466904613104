import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
  useRoute,
  useRouter,
} from 'vue-router'
import { h } from 'vue'
import { RouterView } from 'vue-router'

import { ROUTE_NAMES } from '@/enums'

// import { Home, About, FAQ } from '@/pages'

const routes: RouteRecordRaw[] = [
  {
    path: '/:catchAll(.*)',
    redirect: { name: ROUTE_NAMES.faq },
  },
  // {
  //   path: '/',
  //   name: ROUTE_NAMES.home,
  //   component: { render: () => h(RouterView) },
  //   children: [
  //     {
  //       path: 'about',
  //       name: ROUTE_NAMES.about,
  //       component: () => import('@/pages/AboutPage.vue'),
  //     },
  //     {
  //       path: 'faq',
  //       name: ROUTE_NAMES.faq,
  //       component: () => import('@/pages/FAQPage.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/',
  //   name: ROUTE_NAMES.faq,
  //   component: () => import('@/pages/FAQPage.vue'),
  // },
  {
    path: '/about',
    name: ROUTE_NAMES.about,
    component: () => import('@/pages/AboutPage.vue'),
  },
  {
    path: '/faq',
    name: ROUTE_NAMES.faq,
    component: () => import('@/pages/FAQPage.vue'),
  },
  {
    path: '/download',
    name: ROUTE_NAMES.download,
    component: () => import('@/pages/FAQPage.vue'),
  },
  {
    path: '/tos',
    name: ROUTE_NAMES.tos,
    component: () => import('@/pages/TOSPage.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior: () => ({ top: 0, left: 0 }),
  // linkActiveClass: 'active-page', // active class for non-exact links.
  // linkExactActiveClass: 'active-page', // active class for *exact* links.
})

export { router, useRouter, useRoute }
