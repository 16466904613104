<template>
  <main class="overflow-hidden">
    <RouterView :key="$route.fullPath" />
    <!-- <FAQ></FAQ> -->
  </main>
</template>

<script lang="ts">
import { FAQ, HeaderComp } from '@/components'

import { defineComponent, ref, toRefs } from 'vue'

export default defineComponent({
  name: 'home',
  props: {},
  components: {
    FAQ,
    HeaderComp,
  },

  setup() {
    return {}
  },
})
</script>

<style lang="scss" scoped>
// .app-page {
//   grid-template-rows: auto 1fr auto;
//   min-height: 85vh;
// }

// .app-page__logo {
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 2.5%;
//   margin-bottom: 5%;
//   border-radius: 15px;
//   max-width: toRem(200);
// }

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-in-out;
  height: 70vh;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  height: 100vh;
}
</style>
