<template>
  <div class="app-logo">
    <img
      :src="XahodLogo"
      alt="xahod"
      class="app-logo-img"
      v-if="schemes == 'primary'"
    />
    <img
      :src="XahodLogoWhite"
      alt="xahod"
      class="app-logo-img"
      v-if="schemes === 'white-on-black'"
    />
    <div class="logo-info" v-if="showSlogan">
      <!-- <h1>XAHOD</h1>
      <span>XAH on Demand</span> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import XahodLogo from '/branding/xahod_logo.svg'
import XahodLogoWhite from '/branding/xahod_white_transparent.svg'

enum SCHEMES {
  primary = 'primary',
  whiteOnPrimary = 'white-on-primary',
  whiteOnBlack = 'white-on-black', // Black = tertiary? TODO: Revisit this at some point.
  blackOnWhite = 'black-on-white',
}

enum MODIFICATIONS {
  big = 'big',
  medium = 'medium',
  small = 'small',
}

export default defineComponent({
  name: 'AppLogo',
  props: {
    showSlogan: { type: Boolean, default: false },
    schemes: {
      type: String as PropType<SCHEMES>,
      default: SCHEMES.primary,
    },
    modifications: {
      type: String as PropType<MODIFICATIONS>,
      default: MODIFICATIONS.medium,
    },
  },
  setup(props, { attrs }) {
    console.log(props.schemes)
    const logo_classes = computed(() => {
      const schemes = props.schemes
        .split(' ')
        .filter((el) => Boolean(el))
        .map((el) => `app-logo--${el}`)

      const modifications = props.modifications
        .split(' ')
        .filter((el) => Boolean(el))
        .map((el) => `app-logo--${el}`)

      return schemes.concat(modifications).join(' ')
    })
    return {
      XahodLogo,
      XahodLogoWhite,
      logo_classes,
    }
  },
})
</script>

<style lang="scss" scoped>
// .app-logo {
//   display: flex;
//   flex-direction: row;
//   position: relative;
// }

.app-logo-img {
  // &--primary {
  //   // TODO: Manage svg in css
  // }
  min-height: toRem(60);
  width: 150px;

  // &--big {
  //   min-height: toRem(60);
  //   width: 200px;
  // }

  // &--medium {
  //   width: 120px;
  // }

  // &--small {
  //   width: 70px;
  // }
}

.logo-info {
  display: grid;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-left: 10px;
}

.logo-info h1 {
  color: var(--xahod-black);
  font-weight: 900;
  font-size: toRem(25);
  letter-spacing: 1;
  align-self: end;
  line-height: toRem(19);
}

.logo-info span {
  color: var(--xahod-black);
  font-size: toRem(10);
  letter-spacing: 1;
  margin-left: 2px;
  grid-row-start: 2;
  grid-column-start: 1;
  display: flex;
  font-weight: 300;
}

.app-logo__img {
  width: 70px;
}

.app-logo__link {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
</style>
