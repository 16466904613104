<template>
  <div class="bg-xd_red">
    <div
      class="mx-auto max-w-md pt-24 px-4 sm:max-w-3xl lg:pt-32 sm:px-6 lg:max-w-7xl lg:px-8 sm:mobileUI"
    >
      <div class="lg:grid lg:grid-cols-3 lg:gap-8">
        <div>
          <h1
            class="text-4xl font-bold text-xd_black sm:text-5xl lg:text-7xl font-primary"
          >
            Frequently asked questions
          </h1>
          <p class="mt-8 text-lg text-xd_black font-secondary">
            Can’t find the answer you’re looking for? Reach out to our
            <a href="#" class="font-medium text-xd_black hover:text-xd_white"
              >customer support</a
            >
            team.
          </p>
        </div>
        <div class="mt-12 lg:col-span-2 lg:mt-0">
          <dl class="space-y-12">
            <div v-for="faq in faqs" :key="faq.id">
              <dt class="text-2xl font-medium text-xd_black font-primary">
                {{ faq.question }}
              </dt>
              <dd class="mt-2 text-base text-xd_black font-secondary">
                {{ faq.answer }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <section aria-labelledby="offices-heading">
      <div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-16 lg:px-8"></div>
    </section>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'faq',
  props: {},
  setup(props) {
    const faqs = [
      {
        id: 1,
        question: 'What does XAHOD mean or stand for?',
        answer:
          'XAH on Demand. We wanted to make it super simple for XRP holders to convert XRP to XAH with the click of a button to explore the Xahau network and participate in in the natural 4% yield which can be claimed monthly.',
      },
      {
        id: 2,
        question: 'What is XAH?',
        answer: 'XAH is the network token that powers the Xahau network.',
      },
      {
        id: 3,
        question: 'What is the Xahau network?',
        answer:
          'Xahau is an interoperable sister chain to the XRPL. It has a few innovations including a new governance structure and most importantly, super simple “smart contracts” called Hooks. The major advantage of Hooks is that they can be written in “Web2” languages like C, Web Assembly, and soon Javascript. As outsiders and industry veterans, we think this is a massive innovation enabling rapid automation development for payments and real world use cases. ',
      },
      {
        id: 4,
        question: 'What’s up with the pizza?',
        answer:
          'Xahau network. XAH ticker. Prouncounced “ZAH.” Like PI[ZZA]. Who doesn’t like pizza?',
      },
      {
        id: 5,
        question: 'Legal stuff?',
        answer:
          'See our terms and conditions here. All is transparent and summarized for your convenience. Our company is domiciled in Ireland and will most certainly follow all required EU, national, and other laws.',
      },
    ]

    return {
      faqs,
    }
  },
})
</script>

<style scoped>
.mobileUI {
  margin-top: 50px;
}
</style>
