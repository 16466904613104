// import App from "./App.vue";
import { router } from '@/router'
import App from '@/App.vue'
import './style.css'
import { createApp, getCurrentInstance, h } from 'vue'
// import { AppLogo, HeaderComp, FooterComp, FAQ } from './components'
import { i18n } from '@/localization'
import { useI18n } from 'vue-i18n'
import { config } from '@config'

const app = createApp({
  setup() {
    const app = getCurrentInstance()
    const { t, locale } = useI18n({ useScope: 'global' })
    if (app) {
      app.appContext.config.globalProperties.$t = t
      app.appContext.config.globalProperties.$locale = locale
    }
  },
  render: () => h(App),
})

app.config.globalProperties.$config = config

app.use(router).use(i18n)
//   .component('AppLogo', AppLogo)
//   .component('HeaderComp', HeaderComp)
//   .component('FooterComp', FooterComp)
//   .component('FAQ', FAQ)
app.mount('#app')
